<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th v-if="$store.state.user.user.admin">
                <v-checkbox v-model="checkAll" />
              </th>
              <th
                v-for="(header, i) in table.headers"
                :id="`header_${i}`"
                :key="`header_${i}`"
                :style="{ width: header.width, textAlign: header.textCenter ? 'center' : 'left' }"
                class="text-truncate"
              >
                <template v-if="header.name === '구매자'">
                  <v-btn
                    text
                    small
                    @click="sortByUser"
                  >
                    {{ header.name }}
                    <v-icon v-if="order === 'desc'">
                      mdi-sort-alphabetical-descending
                    </v-icon>
                    <v-icon v-else-if="order === 'asc'">
                      mdi-sort-alphabetical-ascending
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else-if="header.name === '구매일'">
                  <v-btn
                    text
                    small
                    @click="sortByTime"
                  >
                    {{ header.name }}
                    <v-icon v-if="order === 'desc'">
                      mdi-sort-alphabetical-descending
                    </v-icon>
                    <v-icon v-else-if="order === 'asc'">
                      mdi-sort-alphabetical-ascending
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  {{ header.name }}
                </template>
              </th>
            </tr>
          </thead>
          <tbody v-if="notices && notices.length > 0">
            <tr v-for="notice in notices" :key="notice.idx" :style="{ backgroundColor: '#616161' }">
              <td class="text-center" @click="viewDetail(notice)">
                <v-icon>
                  mdi-bullhorn
                </v-icon>
              </td>
              <td class="text-center" @click="viewDetail(notice)">
                -
              </td>
              <td @click="viewDetail(notice)">
                <router-link :to="`/article/${notice.idx}`">
                  <span class="text-truncate">
                    {{ notice.title }}
                  </span>
                </router-link>
              </td>
              <td class="text-center text-truncate" @click="viewDetail(notice)">
                관리자
              </td>
              <td class="text-center" @click="viewDetail(notice)">
                <span v-if="new Date().getTime() - notice.registerDate < 1000 * 60 * 60 * 7">
                  {{ notice.registerDate | moment('from', 'now') }}
                </span>
                <span v-else>
                  {{ notice.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="pagination && pagination.list && pagination.list.length > 0">
            <tr v-for="article in pagination.list" :key="`article_${article.idx}`">
              <td v-if="$store.state.user.user.admin">
                <v-checkbox v-model="selected" :value="article.idx" />
              </td>
              <td class="text-center text-truncate" @click="viewDetail(article)">
                {{ article.company.companyName }}
              </td>
              <td class="text-center" @click="viewDetail(article)">
                <span v-if="article.productRegistration && article.productRegistration.product && article.productRegistration && article.productRegistration.product.month" class="text-truncate">
                  {{ article.type === '배송완료' ? '구매확정' : article.type }}
                </span>
                <span v-else class="text-truncate">
                  {{ article.type }}
                </span>
              </td>
              <td @click="viewDetail(article)">
                <router-link :to="`/article/${article.idx}`">
                  <v-chip
                    v-if="article.productRegistration && article.productRegistration.product"
                    label
                    small
                    class="mr-1"
                  >
                    {{ article.productRegistration.product.productName }}
                  </v-chip>
                  <span class="text-truncate">
                    {{ article.title }}
                  </span>
                </router-link>
              </td>
              <td class="text-center" @click="viewDetail(article)">
                {{ article.alias ? article.alias.username : (article.users ? article.users.username : '정보없음') }}
              </td>
              <td class="text-center" @click="viewDetail(article)">
                <span v-if="new Date().getTime() - article.purchaseDate < 1000 * 60 * 60 * 7">
                  {{ article.purchaseDate | moment('from', 'now') }}
                </span>
                <span v-else>
                  {{ article.purchaseDate | moment('YYYY.MM.DD.') }}
                </span>
                <template v-if="article.modifyDate">
                  <br>
                  <span v-if="new Date().getTime() - article.modifyDate < 1000 * 60 * 60 * 7" class="text-caption">
                    ({{ article.modifyDate | moment('from', 'now') }} 수정)
                  </span>
                  <span v-else class="text-caption">
                    ({{ article.modifyDate | moment('YYYY.MM.DD. HH:mm:ss') }} 수정)
                  </span>
                </template>
              </td>
              <td class="text-center" @click="viewDetail(article)">
                <span v-if="article.status === 'pending'" class="grey--text">
                  대기
                </span>
                <span v-else-if="article.status === 'confirm'" class="light-green--text">
                  승인
                </span>
                <span v-else-if="article.status === 'reject'" class="error--text">
                  반려
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="$store.state.user.user.admin ? table.headers.length + 1 : table.headers.length">
                <p class="text-center mb-0 py-3">
                  검색된 게시물이 존재하지 않습니다.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BoardContent',
    props: {
      pagination: {},
      notices: {},
    },
    data () {
      return {
        table: {
          headers: [
            {
              name: '업체',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '단계',
              width: '10%',
              textCenter: true,
            },
            {
              name: '제목',
              width: 'auto',
              textCenter: false,
            },
            {
              name: '구매자',
              width: '15%',
              textCenter: true,
            },
            {
              name: '구매일',
              width: '15%',
              textCenter: true,
            },
            {
              name: '상태',
              width: '10%',
              textCenter: true,
            },
          ],
        },
        selected: [],
        orderField: '',
        order: 'asc',
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination && this.pagination.list ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      viewDetail (notice) {
        const url = `/article/${notice.idx}`
        this.$router.push(url)
      },
      sortByTime () {
        this.orderField = 'purchaseDate'
        this.$route.query.orderField = 'purchaseDate'
        if (this.order === 'asc') {
          this.order = 'desc'
          this.$route.query.order = 'desc'
        } else {
          this.order = 'asc'
          this.$route.query.order = 'asc'
        }
        this.addParamsToLocation(this.$route.query)
        this.$emit('search', this.$route.query)
      },
      sortByUser () {
        this.orderField = 'purchaseName'
        this.$route.query.orderField = 'purchaseName'
        if (this.order === 'asc') {
          this.order = 'desc'
          this.$route.query.order = 'desc'
        } else {
          this.order = 'asc'
          this.$route.query.order = 'asc'
        }
        this.addParamsToLocation(this.$route.query)
        this.$emit('search', this.$route.query)
      },
      addParamsToLocation (params) {
        history.pushState(
          {},
          null,
          this.$route.path +
            '?' +
            Object.keys(params)
              .map(key => {
                return (
                  encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                )
              })
              .join('&')
        )
      },
    },
  }
</script>

<style scoped>

</style>
