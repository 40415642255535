<template>
  <v-card>
    <v-card-title class="pt-10 pt-md-3">
      게시글 작성
    </v-card-title>
    <v-card-text>
      <v-select
        v-if="aliases && aliases.length > 0"
        v-model="alias"
        :items="aliasUsers"
        :search-input.sync="aliasKeyword"
        :readonly="readOnly"
        prepend-inner-icon="mdi-account-search"
        dense
        label="글쓴이"
        placeholder="사용자 검색"
        no-data-text="검색된 사용자가 없습니다."
        class="pt-5"
      />
      <v-autocomplete
        v-if="companies && companies.length > 0"
        v-model="company.idx"
        :items="companies"
        :search-input.sync="companyKeyword"
        prepend-inner-icon="mdi-store-search"
        dense
        label="구매 업체"
        placeholder="업체 검색"
        no-data-text="검색된 업체가 없습니다."
        class="pt-5"
      />
      <v-menu
        v-if="articleType !== 'buy'"
        ref="date-menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="purchaseDate"
            label="구매 날짜"
            prepend-icon="mdi-calendar"
            readonly
            :disabled="readOnly"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="purchaseDate"
          locale="ko"
          :active-picker.sync="activePicker"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
          min="1950-01-01"
          :readonly="readOnly"
          :disabled="readOnly"
          @change="saveDate"
        />
      </v-menu>
      <v-text-field
        v-if="!!productRegistration"
        :value="productRegistration.product.productName"
        label="키워드"
        readonly
        dense
        hide-details
        disabled
      />
      <v-text-field
        v-model="title"
        label="Title"
      />
      <Editor ref="editor" />
    </v-card-text>
    <v-card-actions
      class="mt-10"
      :class="{
        'justify-end': !mobile,
        'd-flex': mobile,
        'pt-15': mobile,
        'flex-wrap': mobile
      }"
    >
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile, 'mb-2': mobile }"
        :width="mobile ? '100%' : ''"
        @click="registerBoard"
      >
        저장하기
      </v-btn>
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile }"
        :width="mobile ? '100%' : ''"
        @click="close"
      >
        창닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Editor from '@/components/core/Editor'
  export default {
    name: 'BoardWrite',
    components: { Editor },
    props: [
      'companies',
      'productRegistration',
    ],
    data () {
      return {
        title: '',
        articleType: '',
        articleTypes: [
          {
            text: '구매',
            value: 'buy',
          },
          {
            text: '리뷰',
            value: 'review',
          },
          {
            text: '배송완료',
            value: 'delivery',
          },
        ],
        readOnly: !!this.productRegistration,
        aliases: [],
        alias: null,
        aliasKeyword: null,
        company: { idx: this.productRegistration ? this.productRegistration.product.company.idx : null },
        companyKeyword: null,
        dateMenu: false,
        activePicker: null,
        purchaseDate: this.productRegistration ? this.$parseDate(this.productRegistration.purchaseDate) : this.getToday(),
        isLoading: false,
      }
    },
    computed: {
      content () {
        return this.$refs.editor.getContent()
      },
      mobile () {
        return this.$isMobile
      },
      aliasUsers () {
        const array = []
        if (this.productRegistration && this.productRegistration.alias && this.productRegistration.alias.idx) {
          const data = {
            text: this.productRegistration.alias.username + '(' + this.productRegistration.alias.id + ')',
            value: this.productRegistration.alias.idx,
          }
          array.push(data)
          this.updateAlias(this.productRegistration.alias)
        } else {
          array.push({ text: this.$store.state.user.user.username + '(' + this.$store.state.user.user.id + ')', value: null })
          for (const alias of this.aliases) {
            const data = {
              text: alias.username + '(' + alias.id + ')',
              value: alias.idx,
            }
            array.push(data)
          }
        }
        return array
      },
    },
    watch: {
      dateMenu (val) {
        val && setTimeout(() => { this.activePicker = 'YEAR' })
      },
    },
    beforeMount () {
      this.getAlias()
    },
    methods: {
      updateAlias (alias) {
        this.alias = alias.idx
      },
      getToday () {
        const today = new Date()
        const date = this.$moment(today).format('YYYY-MM-DD')
        return date
      },
      async getAlias () {
        const url = '/user/alias'
        await this.$axios.get(url).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.aliases = res.data
          } else {
            this.alias = null
          }
        })
      },
      close () {
        this.$refs.editor.setContent('')
        this.articleType = ''
        this.title = ''
        this.dateMenu = false
        this.activePicker = null
        this.purchaseDate = this.getToday()
        this.alias = null
        this.$emit('close-dialog')
      },
      saveDate (date) {
        this.$refs['date-menu'].save(date)
      },
      async registerBoard () {
        const url = '/article/register'
        const data = {
          title: this.title,
          alias: { idx: this.alias },
          content: this.content,
          purchaseDate: this.purchaseDate ? Date.parse(this.purchaseDate) : null,
          company: this.company,
          productRegistration: this.productRegistration,
        }
        await this.$axios.post(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.close()
            if (confirm('게시글이 등록되었습니다. 해당 게시글로 이동하시겠습니까?')) {
              this.$router.push(`/article/${res.data.message}`)
            }
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
