<template>
  <div class="d-flex justify-center align-center">
    <v-list>
      <v-list-item class="px-1 d-inline-block">
        <v-btn
          class="mx-2"
          fab
          x-small
          elevation="1"
          @click="goToFirstPage"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-list-item>
      <template v-for="index in pages">
        <v-list-item v-if="index >= start && index <= end" :key="index" class="px-0" :style="{ display: 'inline-block' }">
          <v-btn
            class="mx-2"
            fab
            :dark="$vuetify.theme.dark ? pagination.pageNum !== index : pagination.pageNum === index"
            :light="$vuetify.theme.dark ? pagination.pageNum === index : pagination.pageNum !== index"
            x-small
            :elevation="pageNum == index ? '5' : '1'"
            :to="{ path: baseUrl + index, query: query }"
            @click="goToPage(index)"
          >
            {{ index }}
          </v-btn>
        </v-list-item>
      </template>
      <v-list-item class="px-1" :style="{ display: 'inline-block' }">
        <v-btn
          class="mx-2"
          fab
          x-small
          elevation="1"
          @click="goToLastPage"
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: [
      'pagination',
      'query',
      'showNum',
      'baseUrl',
    ],
    computed: {
      start () {
        const pageNum = this.pagination.pageNum
        const maxPage = this.pagination.maxPageNum
        let page = 1
        if (pageNum < 3) {
          page = 1
        } else {
          if (pageNum > maxPage - 3) {
            page = maxPage - 4
          } else {
            page = pageNum - 2
          }
        }
        return page
      },
      end () {
        const pageNum = this.pagination.pageNum
        const maxPage = this.pagination.maxPageNum
        let page
        if (pageNum > maxPage - 3) {
          if (pageNum < 3) {
            page = 5
          } else {
            page = maxPage
          }
        } else {
          if (pageNum < 3) {
            page = 5
          } else {
            page = pageNum + 2
          }
        }
        return page
      },
      totalDataSize () {
        return this.pagination && this.pagination.totalDataSize ? this.pagination.totalDataSize : 0
      },
      pages () {
        return this.pagination && this.pagination.maxPageNum ? this.pagination.maxPageNum : 1
      },
      pageNum () {
        return this.pagination && this.pagination.pageNum ? this.pagination.pageNum : 1
      },
      queries () {
        let query = '?'
        if (this.query) {
          for (const [key, value] of Object.entries(this.query)) {
            query += (key + '=' + value + '&')
          }
          query = query.substring(0, query.length - 1)
        }
        return query
      },
    },
    methods: {
      goToPage (index) {
        this.$router.push(this.baseUrl + index + this.queries).catch(() => {})
      },
      goToFirstPage () {
        this.$router.push(this.baseUrl + '1' + this.queries).catch(() => {})
      },
      goToLastPage () {
        this.$router.push(this.baseUrl + this.pages + this.queries).catch(() => {})
      },
      forceUpdate () {
        this.$forceUpdate()
      },
    },
  }
</script>

<style scoped>

</style>
