<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <BoardHeader :pagination="pagination" />
      <BoardSearch ref="search" :companies="companies" @search="getPagination" />
      <BoardContent ref="content" :notices="notices" :pagination="pagination" @search="getPagination" />
      <v-sheet class="px-3 pb-15 pb-md-3">
        <v-row>
          <v-col v-if="$store.state.user.user.admin" cols="12" md="2" class="d-none d-md-block">
            <v-btn
              @click="deleteArticles"
            >
              삭제
            </v-btn>
          </v-col>
          <v-col cols="12" md="8" :offset-md="$store.state.user.user.admin ? '0' : '2'">
            <Pagination ref="pagination" :pagination="pagination" :show-num="7" :base-url="'/board/'" :query="$route.query" />
          </v-col>
          <v-col cols="12" md="2" class="text-right">
            <v-btn
              v-if="$store.state.user.user.admin"
              large
              class="d-inline-block d-md-none mr-1"
              @click="deleteArticles"
            >
              삭제
            </v-btn>
            <v-dialog
              v-model="isShowWrite"
              :width="mobile ? '100%' : '600'"
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  글쓰기
                </v-btn>
              </template>
              <BoardWrite :companies="companies" @close-dialog="closeDialog" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import BoardHeader from '@/views/board/Header'
  import BoardContent from '@/views/board/Content'
  import Pagination from '@/components/core/Pagination'
  import BoardSearch from '@/views/board/Search'
  import BoardWrite from '@/views/board/Register'
  export default {
    name: 'Board',
    components: { BoardWrite, BoardSearch, Pagination, BoardContent, BoardHeader },
    data () {
      return {
        search: {
          keyword: '',
        },
        notices: [],
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: '10',
        isShowWrite: false,
        companies: [],
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      pageNum () {
        return this.$route.params.pageNum
      },
      status () {
        return this.$route.query.status
      },
      type () {
        return this.$route.query.type
      },
      keyword () {
        return this.$route.query.keyword
      },
      startDate () {
        return this.$route.query.sdt
      },
      endDate () {
        return this.$route.query.edt
      },
      searchField () {
        return this.$route.query.searchField
      },
      order () {
        return this.$route.query.order
      },
      orderField () {
        return this.$route.query.orderField
      },
      company () {
        return this.$route.query.company
      },
      month () {
        const mon = this.$route.query.month
        return typeof mon === 'boolean' ? String(mon) : mon
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
      month (val) {
        const query = {}
        if (val) {
          query.month = val
        }
        this.$refs.search.company.idx = null
        this.$router.push({ path: '/board/1', query: query })
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
      this.getCompanies()
      this.getNotices()
    },
    methods: {
      closeDialog () {
        this.isShowWrite = false
        this.getPagination()
      },
      getNotices () {
        this.$axios.get('article/notice')
          .then(res => {
            if (res && res.data) {
              this.notices = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getPagination (payload) {
        const data = {
          pageNum: this.pageNum,
          pageDataSize: this.pageDataSize,
          keyword: payload && payload.keyword ? payload.keyword : this.keyword,
          status: payload && payload.status ? payload.status : this.status,
          type: payload && payload.type ? payload.type : this.type,
          searchField: payload && payload.searchField ? payload.searchField : this.searchField,
          company: payload && payload.company ? payload.company : this.company,
          sdt: payload && payload.startDate ? payload.startDate : this.getQueryDate(this.startDate, 'start'),
          edt: payload && payload.endDate ? payload.endDate : this.getQueryDate(this.endDate, 'end'),
          order: payload && payload.order ? payload.order : this.order,
          orderField: payload && payload.orderField ? payload.orderField : this.orderField,
          month: payload && payload.month ? payload.month : this.month,
        }
        this.$axios.get('article/articles', {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async deleteArticles () {
        const articles = this.$refs.content.selected
        if (articles.length === 0) {
          alert('게시글을 선택해주세요.')
          return
        }
        const url = '/manage/article'
        if (confirm(articles.length + '개의 게시글을 삭제하시겠습니까?')) {
          await this.$axios.delete(url, { data: articles }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.getPagination()
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      getQueryDate (date, type) {
        if (date) {
          let time
          if (isNaN(date)) {
            const year = date.split('-')[0]
            const month = date.split('-')[1]
            const day = date.split('-')[2]
            let d
            if (type === 'start') {
              d = new Date(year, month - 1, day, 0, 0, 0, 0)
            } else {
              d = new Date(year, month - 1, day, 23, 59, 59, 999)
            }
            time = d.getTime()
          } else {
            const d = new Date()
            time = d.setTime(date)
          }
          return time
        } else {
          return null
        }
      },
    },
  }
</script>

<style scoped>

</style>
