<template>
  <v-row>
    <v-col cols="12">
      <v-subheader v-if="month" class="px-0">
        Bought / Monthly Usage Review List. ({{ pagination ? pagination.totalDataSize : 0 }})
      </v-subheader>
      <v-subheader v-else class="px-0">
        Bought / Review / Delivery Complete Capture List. ({{ pagination ? pagination.totalDataSize : 0 }})
      </v-subheader>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BoardHeader',
    props: {
      pagination: {},
    },
    computed: {
      month () {
        const mon = this.$route.query.month
        return typeof mon === 'boolean' ? String(mon) : mon
      },
    },
  }
</script>

<style scoped>

</style>
