<template>
  <v-row class="mt-10">
    <v-col cols="3">
      <v-select
        v-model="status"
        label="Status"
        :items="items.status"
        dense
        hide-details
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="type"
        label="Step"
        :items="items.type"
        dense
        hide-details
      />
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="startDate"
            label="Start"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          locale="ko"
          type="date"
          @change="saveStartDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="endDate"
            label="End"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          locale="ko"
          type="date"
          @change="saveEndDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        v-if="parseCompany && parseCompany.length > 0"
        v-model="company.idx"
        :items="parseCompany"
        :search-input.sync="companyKeyword"
        label="Store"
        dense
        hide-details
        placeholder="업체 검색"
        no-data-text="검색된 업체가 없습니다."
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="searchField"
        :items="searchFields"
        label="Field"
        dense
        hide-details
        placeholder="조건"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        dense
        hide-details
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BoardSearch',
    props: [
      'companies',
    ],
    data () {
      return {
        status: this.$route.query.status ? this.$route.query.status : '',
        type: this.$route.query.type ? this.$route.query.type : '',
        sdt: this.$route.query.sdt ? this.parseDate(this.$route.query.sdt) : null,
        edt: this.$route.query.edt ? this.parseDate(this.$route.query.edt) : null,
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        searchField: this.$route.query.searchField ? this.$route.query.searchField : null,
        searchFields: [
          {
            text: '전체',
            value: null,
          },
          {
            text: '제목',
            value: 'title',
          },
          {
            text: '업체',
            value: 'company',
          },
          {
            text: '구매자',
            value: 'user',
          },
          {
            text: '키워드',
            value: 'product',
          },
        ],
        company: {
          idx: this.$route.query.company ? parseInt(this.$route.query.company | String) : null,
        },
        companyKeyword: null,
        items: {
          status: [
            {
              text: '전체',
              value: '',
            },
            {
              text: '초기값',
              value: 'blank',
            },
            {
              text: '대기',
              value: 'pending',
            },
            {
              text: '승인',
              value: 'confirm',
            },
            {
              text: '반려',
              value: 'reject',
            },
          ],
          type: [
            {
              text: '전체',
              value: '',
            },
            {
              text: '대기',
              value: 'pending',
            },
            {
              text: '상품구매',
              value: 'buy',
            },
            {
              text: '리뷰작성',
              value: 'review',
            },
            {
              text: this.$route.query.month ? '구매확정' : '배송완료',
              value: 'delivery',
            },
          ],
        },
      }
    },
    computed: {
      month () {
        return this.$route.query.month || false
      },
      parseCompany () {
        const array = []
        array.push({
          text: '전체',
          value: null,
        })
        for (const company of this.companies) {
          array.push(company)
        }
        return array
      },
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const year = this.sdt.split('-')[0]
            const month = this.sdt.split('-')[1]
            const day = this.sdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const year = this.edt.split('-')[0]
            const month = this.edt.split('-')[1]
            const day = this.edt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
    },
    methods: {
      search () {
        const data = {
          status: this.status ? this.status : '',
          type: this.type ? this.type : '',
          keyword: this.keyword ? this.keyword : '',
          searchField: this.searchField ? this.searchField : '',
          company: this.company.idx ? this.company.idx : '',
          sdt: this.getQueryDate(this.startDate, 'start') ? this.startDate : '',
          edt: this.getQueryDate(this.endDate, 'end') ? this.endDate : '',
          month: this.month,
        }
        this.$router.push({
          path: '/board/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
      getQueryDate (date, type) {
        if (date) {
          const year = date.split('-')[0]
          const month = date.split('-')[1]
          const day = date.split('-')[2]
          let d
          if (type === 'start') {
            d = new Date(year, month - 1, day, 0, 0, 0, 0)
          } else {
            d = new Date(year, month - 1, day, 23, 59, 59, 999)
          }
          return d.getTime()
        } else {
          return null
        }
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY-MM-DD')
        } else {
          return date
        }
      },
    },
  }
</script>

<style scoped>

</style>
